import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Underline from "../components/Underline/Underline"
import * as EatingStyles from "./disordered-eating-and-eating-disorders.module.css"
import Banner from "../components/Banner/Banner"
import CTA from "../components/CTA/CTA"
import Button from "../components/Button/Button"

export default function Eating({ data }) {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content="Disordered Eating & Eating Disorders | Paula Abbey Counselling & Psychotherapy" />
                <meta name="description" content="I offer specialist eating disorder work - a holistic approach within a humanistic framework, which uses a vast range of practical interventions and nutritional rehabilitation guidance" />
            </Helmet>
            <Banner image={data.background.childImageSharp.fluid}>
                <h1>Disordered eating and eating disorders.</h1>
            </Banner>
            <section>
                <div className={EatingStyles.text}>
                    <Underline>
                        <h2>Food and well-being</h2>
                    </Underline>
                    <p>A healthy, well-balanced diet brings clarity and a sense of well-being, whereas an inadequate diet can lead to fatigue, stress or even anxiety and depression.</p>
                    <p>Often, food is what we turn to, or turn away from, in an attempt to cope with uncomfortable feelings such as anger, sadness, loneliness or anxiety, something which may feel familiar to us all to some degree. However, how do we know when thoughts and behaviours surrounding food, our shape and size, are becoming a problem and out of control?</p>
                    <p>Perhaps…</p>
                    <ul>
                        <li>thoughts about food and weight are taking the pleasure out of your life?</li>
                        <li>you worry excessively about your weight, preoccupied with calories and weight gain?</li>
                        <li>you are secretive about what you eat/don't eat, maybe vomiting or using laxatives to avoid gaining weight?</li>
                        <li>you berate or even punish yourself if you eat the foods you are trying to avoid, despite experiencing uncontrollable cravings for these foods?</li>
                        <li>you are driven to exercise excessively.</li>
                        <li>you have tried many times to gain control, and feel confused by conflicting advice, hopeless or like a failure</li>
                        <li>you have spent a long time blaming yourself and believe the answer lies in simply trying harder and finding more willpower?</li>
                    </ul>
                    <p>Your experiences could be new or you may have been dealing with this for a long time. Shame, embarrassment or fear of change may make you reluctant to talk to someone about what you're going through, which can make seeking help difficult. Whatever your experience, you deserve support and to know that recovery is possible.</p>
                </div>
            </section>
            <section style={{backgroundColor: 'var(--primary-50)'}}>
                <div className={EatingStyles.text}>
                    <Underline>
                        <h2>What I offer</h2>
                    </Underline>
                    <p>The realisation that you are not alone with these struggles; that you are not weak or lacking in willpower; that others are going through the same thing; there is relief, a release and things start to change.</p>
                    <p>Alongside my counselling and psychotherapy service, I am a Master Practitioner in Eating Disorders and Obesity, having trained at The National Centre for Eating Disorders (NCFED).</p>
                    <p>I offer specialist eating disorder work - a holistic approach within a humanistic framework, which uses a vast range of practical interventions and nutritional rehabilitation guidance to support change and recovery from eating distress, teaching you the skills and techniques to use between sessions. You will have the opportunity to explore your relationship with food and be helped to make sense of your experiences and underlying issues which are keeping you stuck, so that you can engage more freely and fully in life.</p>
                    <p>Recovery will mean changing eating behaviours which can be a scary prospect for someone with an eating disorder. While this can feel daunting, the aim of recovery is to fight that fear and regain a healthy relationship with food and yourself. I will help you change your habits, your mindset & lifestyle.</p>
                    <p><strong>Please be aware that because I am in a private practice, and Eating Disorders are associated with high physical risk, it is likely that you will also need to see your GP to make sure that those needs are also monitored.</strong></p>
                </div>
            </section>
            <section className={EatingStyles.cta}>
                <CTA>
                    <h2>Make your appointment, today.</h2>
                    <Button type="internal" colour="primary" to="/appointments/">Get in touch</Button>
                </CTA>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        background: file(relativePath: {eq: "images/arms-sea.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 2000, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`